import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import { styled } from 'linaria/react';
import React from 'react';
import { KVButton, KVButtonStyles } from '../../../../Shared/KVButton/KVButton';
import { muiTheme, theme } from '../../../../Shared/Theme/Theme';

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  ${muiTheme.breakpoints.down('sm')} {
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-flow: column;
`;

export const FormItemError = styled.span`
  color: ${theme.color.primary};
  font-size: 1.6rem;
  margin-top: 10px;
`;

export const GreenCheckbox = withStyles({
  root: {
    color: theme.color.grayDark,
    '&$checked': {
      color: theme.color.primary
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const CheckboxLabel = styled.span`
  font-size: 1.6rem;
  color: ${theme.color.grayDark};
`;
