import { FormControlLabel, Grid } from '@material-ui/core';
import queryString from 'query-string';
import { styled } from 'linaria/react';
import { rgba } from 'polished';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { emailRegex } from '../../../../../lib/consts';
import { KVButton } from '../../../../Shared/KVButton/KVButton';
import { muiTheme, theme } from '../../../../Shared/Theme/Theme';
import {
  CheckboxLabel,
  FormItem,
  FormItemError,
  GreenCheckbox
} from './styled';

export interface ContactUsFormProps {
  className?: string;
}
export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const requiredMessage = 'Toto pole je povinné';

const ContactUsFormInner = (props: ContactUsFormProps) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const onSubmit = async (data: any) => {
    if (!agreed) {
      return Swal.fire(
        'Nesúhlasíte ?',
        'Musíte potvrdiť, že súhlasíte so spracovaním vašich údajov',
        'warning'
      );
    }
    
    setSubmitting(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data
      })
    })
      .then(() => {
        Swal.fire(
          'Email úspešne odoslaný',
          'Váš email bol úspešne odoslaný',
          'success'
        );
        setSubmitting(false);
        setAgreed(false);
      })
      .catch(error => alert(error))
      .finally(() => {
        reset();
      });
  };

  return (
    <form
      className={props.className}
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      name="contact"
      action="/contact/thanks/"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormItem>
            <input
              placeholder={`* Meno`}
              name="name"
              ref={register({
                required: true
              })}
            />
            {errors.name && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <input placeholder={`Mobil`} name="mobile" ref={register({})} />
          </FormItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormItem>
            <input
              placeholder={`* Email`}
              name="email"
              ref={register({
                required: true,
                pattern: emailRegex
              })}
            />
            {errors.email && (
              <FormItemError>Zadajte prosím správny email</FormItemError>
            )}
          </FormItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormItem>
            <input placeholder={`Predmet`} name="subject" ref={register({})} />
          </FormItem>
        </Grid>

        <Grid item xs={12}>
          <FormItem>
            <textarea
              rows={5}
              placeholder={`* Chceš sa dozvedieť niečo konkrétne? Máš záujem o tréningy alebo informácie?`}
              name="message"
              ref={register({
                required: true
              })}
            />
            {errors.message && <FormItemError>{requiredMessage}</FormItemError>}
          </FormItem>
        </Grid>

        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={12} md={3}>
            <KVButton
              loading={submitting}
              secondary
              onClick={handleSubmit(onSubmit)}
            >
              Poslať správu
            </KVButton>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormControlLabel
              className="agreement-checkbox"
              control={
                <GreenCheckbox
                  name="agreement"
                  checked={agreed}
                  onChange={e => setAgreed(e.target.checked)}
                />
              }
              label={
                <CheckboxLabel className="checkbox-label">
                  Súhlasím so spracovaním osobných údajov s cieľom zasielania
                  obchodných oznamov, informácií o tréningoch a elektronickej
                  komunikácií.
                </CheckboxLabel>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export const ContactUsForm = styled(ContactUsFormInner)`
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  color: white;
  .Checkbox {
    display: flex;
    flex-flow: row;
    input {
      flex: 0;
    }
  }

  .agreement-checkbox {
    margin-left: 20px;
    flex: 1;

    ${muiTheme.breakpoints.down('sm')} {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }

  input,
  textarea {
    border-radius: 2px;
    display: flex;
    flex: 1;
    padding: 1.5rem;
    border: 2px solid ${theme.color.grayDark};
    line-height: 2.6rem;
    font-size: 1.6rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    ::placeholder {
      color: ${rgba(theme.color.primaryDark, 0.5)};
      opacity: 1; /* Firefox */
    }
  }
`;
